<template>
  <div>
    <!-- 对战福利 -->
    <div class="welfareBox">
      <div class="welfareNav color-white d-flex justify t-c f-13">
        <div class="fl flzx" @click="$router.push({name:'Welfare'})">福利中心</div>
        <div class="fl xrfl" @click="$router.push({name:'newwelfare'})">新人福利</div>
        <div class="fl hyzx" @click="$router.push({name:'vipCenter'})">会员中心</div>
        <div class="fl czfl" @click="$router.push({name:'rechargeWelfare'})">充值福利</div>
        <div class="fla zdfl">对战福利</div>
      </div>

      <div class="main d-flex justity"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.main {
  width: 90%;
  margin: 30px auto;
}
</style>